@use "styles/scss-variables" as v;

html, body {
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  color: var(--color-black);
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-white);
}

h1 {
  font-size: 68px;
  line-height: 86px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0;
}

h2 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0;
}

h3 {
  font-size: 36px;
  line-height: 45px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0;
}

h4 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin: 0;
}

@media (max-width: v.$phone-breakpoint) {
  h1 {
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 2px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 22px;
    line-height: 18px;
    letter-spacing: 1px;
  }
}

input {
  height: 48px;
  font-size: 16px;
  padding: 0 16px;
  border: 1px solid var(--color-black);
  border-radius: 0;

  &.invalid {
    border-color: var(--color-orange);
  }
}
