@use "./scss-variables" as v;


.cursor-pointer {
  cursor: pointer;
}


.page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 120px;
  padding: 120px 120px;

  font-size: 18px;

  @media (max-width: v.$phone-breakpoint) {
    font-size: 16px;

    gap: 80px;
    padding: 48px 24px;
  }

  &.smaller-gap {
    gap: 80px;

    @media (max-width: v.$phone-breakpoint) {
      gap: 40px;
    }
  }
}

.page-content-description {
  font-size: 22px;

  @media (max-width: v.$phone-breakpoint) {
    font-size: 16px;
  }
}

.page-title {
  font-size: 84px;
  line-height: 106px;

  @media (max-width: v.$phone-breakpoint) {
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 2px;
  }
}

.nav-link {
  position: relative;
  text-decoration: none;
  padding: 6px 16px;
  font-size: 18px;
  --bhb-top: 19.5px;
  --bhb-left: 12px;
  --bhb-width: 84px;
  --bhb-height: 17px;
  --bhb-clip-path: polygon(10% 0%, 100% 0%, 85% 100%, 0% 100%);

  &.active {
    color:  var(--color-dark-grey);
    --bhb-top: 6px;
    --bhb-left: 0;
    --bhb-width: 100%;
    --bhb-height: calc(100% - 12px);
    --bhb-clip-path: polygon(10% 0%, 100% 0%, 85% 100%, 0% 100%);
  }
}

.h4-body {
  font-size: 18px;
  line-height: 26px;
  margin: 0;
}


.background-hover-box::before,
.background-hover-box.active::before,
.background-box-always::before,
.background-hover-box-parent .background-hover-box::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: var(--bhb-top);
  left: var(--bhb-left);
  width: var(--bhb-width);
  height: var(--bhb-height);
  background-color: var(--bhb-color);
  clip-path: var(--bhb-clip-path);
  -webkit-clip-path: var(--bhb-clip-path);
}

.btn {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-black);
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 0;
  text-transform: uppercase;
  background: white;
  font-size: 16px;
  font-family: inherit;
  box-shadow: 4px 4px 0 0 var(--shadow-color), 8px 8px 0 0 #1C1B1F;

  /*
  change back to original color if grey looks too bland
  */
  &.highlighted-purple {
    background: var(--color-purple);
  }

  &.highlighted-orange {
    background: var(--color-orange);
  }

  &.purple {
    --shadow-color: var(--color-dark-grey);
  }
  /*buy and sell button hidden until what will be done with it is decided*/
  &.turquoise {
    --shadow-color: var(--color-dark-grey);
  }

  &.orange {
    --shadow-color: var(--color-dark-grey);
  }

  &.neon-teal {
    --shadow-color: var(--color-dark-grey);
  }

  &.yellow {
    --shadow-color: var(--color-dark-grey);
  }

  &.navy-blue {
    --shadow-color: var(--color-dark-grey);
  }

  &.blue {
    --shadow-color: var(--color-dark-grey);
  }



  &:hover {
    box-shadow: 4px 4px 0 0 var(--shadow-color), 8px 8px 0 0 #1C1B1F;
  }

  &:active {
    background: var(--shadow-color);
    box-shadow: 4px 4px 0 0 var(--shadow-color);
  }

}

.horizontal-container {
  display: flex;
  flex-direction: row;
}

.vertical-container {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.phone-adaptive-container {
  display: flex;
  flex-direction: row;

  @media (max-width: v.$phone-breakpoint) {
    flex-direction: column;
  }
}

.text-centered {
  text-align: center;
}

.center {
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.self-center {
  align-self: center;
}

.align-start {
  align-items: flex-start;
}

.row-reverse {
  flex-direction: row-reverse;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

.gap-36 {
  gap: 36px;
}

.gap-40 {
  gap: 40px;
}

.gap-48 {
  gap: 48px;
}

.gap-56 {
  gap: 56px;
}

.horizontal-line {
  width: 100%;
  flex: 1;
  height: 1px;
  background-color: var(--color-black);
}

.vertical-line {
  height: 100%;
  flex: 1;
  width: 1px;
  background-color: var(--color-black);
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

@media (min-width: calc(#{v.$phone-breakpoint} + 1px)) {
  .hide-on-big-screen {
    display: none !important;
  }
}

@media (max-width: v.$phone-breakpoint) {
  .btn {
    width: 100% !important;
    justify-content: center;
  }

  .hide-on-phones {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}
