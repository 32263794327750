* {
  --color-black: #1C1B1F;
  --color-grey: #B0B0B0;
  --color-purple: #BD9EFF;
  --color-turquoise: #ACEEDF;
  --color-orange: #FFA448;
  --color-neon-teal: #55F1F1;
  --color-yellow: #FFE324;
  --color-navy-blue: #00397F;
  --color-blue: #0D83D7;
  --color-dark-grey: #D3D3D3;
  --color-steel-grey:  #848884;
  --color-white: #FFFFFF;
}
